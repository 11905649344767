// Get next sibling nodes

'use strict';

export default function getNextSiblings(elem) {
    // create an empty array
    let siblings = [];

    // loop through next siblings until `null`
    while (elem = elem.nextElementSibling) {
     // push sibling to array
        siblings.push(elem);
    }
    return siblings;
}