// Get previous sibling nodes

'use strict';

export default function getPreviousSiblings(elem) {
    // create an empty array
    let siblings = [];
    
    // loop through previous siblings until `null`
    while (elem = elem.previousElementSibling) {
     // push sibling to array
        siblings.push(elem);
    }
    return siblings;
}