// inputmask.js

'use strict';

const inputmask = document.querySelectorAll('[data-inputmask]');

if(inputmask.length) {
    import(/* webpackChunkName: 'inputmask' */ 'inputmask')
    .then(({default: Inputmask}) => {
        Inputmask().mask(inputmask);

        window.Inputmask = Inputmask;
    })
    .catch(console.warn);
}