// zxcvbn.js (password strength)

'use strict';

const pwstrengths = document.querySelectorAll('[data-zxcvbn]');

if(pwstrengths.length) {
    import(/* webpackChunkName: 'zxcvbn' */ 'zxcvbn')
    .then(({default: zxcvbn}) => {
        pwstrengths.forEach((pwstrength) => {
            const userOptions = pwstrength.dataset.zxcvbn ? JSON.parse(pwstrength.dataset.zxcvbn) : {};
    
            const defaultOptions = {
                ratings: ['Very weak', 'Weak', 'OK', 'Strong', 'Very strong'],
                allProgressBarClasses: ['bg-danger', 'bg-warning', 'bg-success'],
                progressBarClasses: ['bg-danger', 'bg-danger', 'bg-warning', 'bg-success', 'bg-success']
            };
    
            const options = {
                ...defaultOptions,
                ...userOptions
            };
    
            const input = document.querySelector(options.input),
                  text = document.querySelector(options.text);
    
            function UpdateProgressBar() {
                if(input.value) {
                    let result = zxcvbn(input.value, []);
                    let scorePercentage = (result.score + 1) * 20;
    
                    pwstrength.style.width = scorePercentage + '%';
                    text.innerHTML = options.ratings[result.score];
                    text.style.marginLeft = '.5rem';
                    pwstrength.classList.remove(...options.allProgressBarClasses);
                    pwstrength.classList.add(options.progressBarClasses[result.score]);
                } else {
                    pwstrength.style.width = '0%';
                    text.innerHTML = '';
                    text.style.marginLeft = 0;
                    pwstrength.classList.remove(...options.allProgressBarClasses);
                }
            }
    
            UpdateProgressBar();
            
            input.addEventListener('keyup', (e) => {
                UpdateProgressBar();
            });
        });
    })
    .catch(console.warn);
}