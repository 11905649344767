// Dragula

'use strict';

const containers = document.querySelectorAll('[data-sortable]');

if (containers.length) {
    import(/* webpackChunkName: 'dragula' */ 'dragula')
    .then(({default: dragula}) => {
        containers.forEach((container) => {
            let sortables = container.dataset.sortable,
                userOptions = container.dataset.sortableOptions ? JSON.parse(container.dataset.sortableOptions) : {},
                list = [];
    
            if(sortables) {
                sortables = JSON.parse(sortables);
    
                for (let i = 0; i < sortables.length; i++) {
                    list.push((document.querySelector('#' + sortables[i])));
                }
            } else {
                list = [containers[0]];
            }

            dragula(list, userOptions);
        });

        window.Dragula = dragula;
    })
    .catch(console.warn);
}