// Quill

'use strict';

const editors = document.querySelectorAll('[data-quill]');

if (editors.length) {
    import(/* webpackChunkName: 'quill' */ 'quill')
    .then(({default: Quill}) => {
        editors.forEach((editor) => {
            const userOptions = editor.dataset.quill ? JSON.parse(editor.dataset.quill) : {};
    
            const defaultOptions = {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline'],
                        ['link', 'blockquote', 'image'],
                        [
                            {
                                list: 'ordered'
                            },
                            {
                                list: 'bullet'
                            }
                        ]
                    ]
                }
            };
    
            const options = {
                ...defaultOptions,
                ...userOptions
            };
    
            new Quill(editor, options);
        });

        window.Quill = Quill;
    })
    .catch(console.warn);
}