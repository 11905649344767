// Dropzone

'use strict';

const dropzones = document.querySelectorAll('[data-dropzone]');

if(dropzones.length) {
    import(/* webpackChunkName: 'dropzone' */ 'dropzone')
    .then(({default: Dropzone}) => {
        Dropzone.autoDiscover = false;

        let dropzoneTemplate = `
        <form class="form" enctype="multipart/form-data">
            <div class="dz-preview dz-file-preview">
                <div class="dz-details d-flex mb-1">
                    <img class="dz-image img-fluid" data-dz-thumbnail>
                    <div class="dz-file-wrapper text-start w-100">
                        <p class="dz-filename mb-0 fs-4">
                            <span data-dz-name></span> (<span class="dz-size fs-6" data-dz-size></span>)
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center h-20px">
                    <div class="dz-progress progress w-100">
                        <div class="dz-upload progress-bar bg-success" role="progressbar" style="width: 0" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" data-dz-uploadprogress></div>
                    </div>
                    <div class="ms-3">
                        <div class="dz-success-mark text-success">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm6.93,8.2-6.85,9.29a1,1,0,0,1-1.43.19L5.76,13.77a1,1,0,0,1-.15-1.41A1,1,0,0,1,7,12.21l4.08,3.26L17.32,7a1,1,0,0,1,1.39-.21A1,1,0,0,1,18.93,8.2Z" style="fill: currentColor"/></svg>                      
                        </div>
                        <div class="dz-error-mark text-danger" data-bs-toggle="tooltip" title="data-dz-errormessage">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16"><path d="M12,0A12.24,12.24,0,0,0,3.47,3.65,11.85,11.85,0,0,0,0,12.21,11.78,11.78,0,0,0,11.8,24H12A12.11,12.11,0,0,0,24,11.79h0A11.77,11.77,0,0,0,12,0ZM10.5,16.54A1.48,1.48,0,0,1,12,15h0a1.53,1.53,0,0,1,1.52,1.47A1.47,1.47,0,0,1,12.05,18h0A1.53,1.53,0,0,1,10.5,16.54Zm.5-4v-6a1,1,0,0,1,2,0v6a1,1,0,0,1-2,0Z" style="fill: currentColor"/></svg>
                        </div>
                    </div>
                </div>
            </div>
        </form>`;
        
        dropzones.forEach((dropzone) => {
            const userOptions = dropzone.dataset.dropzone ? JSON.parse(dropzone.dataset.dropzone) : {};

            const defaultOptions = {
                url: 'https://webinning.co.uk/dashly/upload.php', // replace with your server's path
                previewTemplate: dropzoneTemplate,
                uploadMultiple: true,
                autoProcessQueue: true
            }

            const options = {
                ...defaultOptions,
                ...userOptions
            };

            let uploadButton = document.querySelector('[data-upload-files]'),
                cancelButton = document.querySelector('[data-cancel-files]');

            const myDropzone = new Dropzone(dropzone, options);
            
            myDropzone.on('addedfile', (file) => {
                dropzone.querySelector('.dz-message').style.display = 'none';
            });
            
            myDropzone.on('removedfile', (file) => {
                dropzone.querySelector('.dz-message').style.display = 'block';
            });

            myDropzone.on('error', (file, message) => {
                const tooltips = dropzone.querySelectorAll('.dz-preview .dz-error-mark');

                if(tooltips.length) {
                    tooltips.forEach((tooltip) => {
                        let bsTooltip = new Tooltip(tooltip);
    
                        // update
                        tooltip.title = message;
                        bsTooltip = new Tooltip(tooltip);
                    });
                }
            });

            myDropzone.on('complete', (file, message) => {
                setTimeout(() => {
                    if(cancelButton) {
                        cancelButton.click();
                    }
                }, 2000);
            });

            if(uploadButton) {
                uploadButton.addEventListener('click', (e) => {
                    e.preventDefault();
                    myDropzone.processQueue();
                });   
            }

            if(cancelButton) {
                cancelButton.addEventListener('click', (e) => {
                    e.preventDefault();
                    myDropzone.removeAllFiles(true);
                });
            }
        });

        window.Dropzone = Dropzone;
    })
    .catch(console.warn);
}