// highlight.js

'use strict';

const highlights = document.querySelectorAll('.highlight'); 

if(highlights.length) {
    Promise.all([
        import(/* webpackChunkName: 'highlightjs' */ 'highlight.js/lib/core'),
        import(/* webpackChunkName: 'highlightjs-xml' */ 'highlight.js/lib/languages/xml'),
        import(/* webpackChunkName: 'highlightjs-javascript' */ 'highlight.js/lib/languages/javascript'),
        import(/* webpackChunkName: 'highlightjs-scss' */ 'highlight.js/lib/languages/scss'),
        import(/* webpackChunkName: 'highlightjs-handlebars' */ 'highlight.js/lib/languages/handlebars'),
        import(/* webpackChunkName: 'highlightjs-bash' */ 'highlight.js/lib/languages/bash')
    ])
    .then(([{default: hljs}, {default: xml}, {default: javascript}, {default: scss}, {default: handlebars}, {default: bash}]) => {
        hljs.registerLanguage('html', xml);
        hljs.registerLanguage('javascript', javascript);
        hljs.registerLanguage('scss', scss);
        hljs.registerLanguage('handlebars', handlebars);
        hljs.registerLanguage('bash', bash);

        highlights.forEach((highlight) => {
            hljs.highlightElement(highlight);
        });

        import(/* webpackChunkName: 'highlightjs-linenumbers' */ 'highlightjs-line-numbers.js')
        .then(() => {
            hljs.initLineNumbersOnLoad();
        })
        .catch(console.warn);

        window.hljs = hljs;
    })
    .catch(console.warn);
};