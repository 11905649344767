// User

import * as helper from './helpers';

const cssPrefix = helper.getCSSVariable('--prefix');


// Sales chart
const salesChart = document.getElementById('salesChart');

if(salesChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(salesChart, {
            // The type of chart we want to create
            type: 'bar',
        
            // The data for our dataset
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Projections',
                        data: [12440, 15021, 10081, 10984, 8409, 12532, 13986, 19227, 12636, 10171, 6753, 15589],
                        backgroundColor: helper.getCSSVariable(`${cssPrefix}primary`)
                    },
                    {
                        label: 'Actual',
                        data: [12357, 13665, 9071, 9914, 5115, 12291, 10010, 19092, 11976, 9174, 5189, 14523],
                        backgroundColor: helper.getCSSVariable(`${cssPrefix}light`),
                        borderRadius: 30
                    }
                ]
            },
        
            // Configuration options
            options: {
                scales: {
                    x: {
                        stacked: true,
                        gridLines: {
                            display: false
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            callback: (value, index, values) => {
                                return value > 0 ? '$' + ((value < 1000000) ? Math.floor(value / 1000) + 'k' : Math.floor(value / 1000000) + 'M') : value;
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: context => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            },
                            labelColor: context => {
                                return {
                                    backgroundColor: context.dataset.backgroundColor
                                };
                            }
                        }
                    }
                }
                
            }
        });
    })
    .catch(console.warn);
}
  

// Income chart
const incomeChart = document.getElementById('incomeChart');
       
if(incomeChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(incomeChart, {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            const {ctx, chartArea} = chart;
                    
                            if (!chartArea) {
                                return null;
                            }
                            return helper.getGradient(
                                ctx,
                                chartArea,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}primary-rgb`)}, 0.5)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}primary-rgb`)}, 0.2)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}primary-rgb`)}, 0)`
                            );
                        },
                        data: [400, 1000, 1000, 2500, 3000, 1500, 4000, 5000, 8000, 6000, 5500, 6500]
                    }
                ]
            },

            // Configuration options
            options: {
                layout: {
                    padding: {
                        top: 2,
                        bottom: -10
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            display: false
                        },
                        grid: {
                            drawOnChartArea: true
                        }
                    },
                    y: {
                        display: false
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });
    })
    .catch(console.warn);
}


// Pageviews chart
const pageViewsChart = document.getElementById('pageViewsChart');

if(pageViewsChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(pageViewsChart, {
            // The type of chart we want to create
            type: 'bar',
        
            // The data for our dataset
            data: {
                labels: ['1 May, 2022', '5 May, 2022', '10 May, 2022', '15 May, 2022', '20 May, 2022', '25 May, 2022', '30 May, 2022'],
                datasets: [
                    {
                        label: 'Projections',
                        data: [17, 20, 24, 34, 12, 10, 8],
                        backgroundColor: helper.getCSSVariable(`${cssPrefix}light`),
                        borderRadius: 30
                    }
                ]
            },
        
            // Configuration options
            options: {
                hoverBackgroundColor: helper.getCSSVariable(`${cssPrefix}primary`),
                barThickness: 7,
                scales: {
                    x: {
                        display: false
                    },
                    y: {
                        display: false
                    }
                },
                plugins: {
                    tooltip: {  
                        callbacks: {
                            label: (context) => {
                                return context.parsed.y + '%';
                            },
                            labelColor: (context) => {
                                return {
                                    backgroundColor: helper.getCSSVariable(`${cssPrefix}primary`)
                                };
                            }
                        }
                    }
                }
                
            }
        });
    })
    .catch(console.warn);
}


// Order status chart
const orderStatusChart = document.getElementById('orderStatusChart');
        
if(orderStatusChart){
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(orderStatusChart, {
            // The type of chart we want to create
            type: 'roundedDoughnut',
        
            // The data for our dataset
            data: {
                labels: [
                    'Delivered',
                    'In progress',
                    'To-do'
                ],
                datasets: [
                    {
                        label: 'Order status',
                        data: [29, 45, 26],
                        backgroundColor: [
                            helper.getCSSVariable(`${cssPrefix}primary`),
                            helper.getCSSVariable(`${cssPrefix}dark`),
                            helper.getCSSVariable(`${cssPrefix}gray-300`)
                        ]
                    }
                ]
            },

            // Configuration options
            options: {
                plugins: {
                    tooltip: {  
                        callbacks: {
                            label: (context) => {
                                return context.parsed + '%';
                            }
                        }
                    }
                }
            }
        });
    })
    .catch(console.warn);
}


// Sales report chart
const salesReportChart = document.getElementById('salesReportChart');

if(salesReportChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(salesReportChart, {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Income',
                        data: [28, 70, 68, 77, 35, 24, 18, 73, 29, 43, 19, 24],
                        borderWidth: 4,
                        borderColor: helper.getCSSVariable(`${cssPrefix}primary`)
                    },
                    {
                        label: 'Expense',
                        data: [18, 23, 79, 37, 19, 45, 55, 72, 79, 57, 32, 59],
                        borderWidth: 4,
                        borderColor: helper.getCSSVariable(`${cssPrefix}dark`),
                        hidden: true
                    }
                ]
            },

            // Configuration options
            options: {
                scales: {
                    y: {
                        ticks: {
                            callback: (value, index, values) => {
                                return value > 0 ? '$' + value + 'k' : value;
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });
    })
    .catch(console.warn);
}


// Current balance chart
const currentBalanceChart = document.getElementById('currentBalanceChart');

if(currentBalanceChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(currentBalanceChart, {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            const {ctx, chartArea} = chart;
                    
                            if (!chartArea) {
                                return null;
                            }
                            return helper.getGradient(
                                ctx,
                                chartArea,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}white-rgb`)}, 0.6)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}white-rgb`)}, 0.3)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}white-rgb`)}, 0)`
                            );
                        },
                        borderWidth: 3,
                        borderColor: helper.getCSSVariable(`${cssPrefix}white`),
                        data: [400, 1000, 1000, 2500, 3000, 1500, 4000, 5000, 8000, 6000, 5500, 6500]
                    }
                ]
            },

            // Configuration options
            options: {
                layout: {
                    padding: {
                        top: 2,
                        bottom: -10
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            display: false
                        }
                    },
                    y: {
                        display: false
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            },
                            labelColor: (context) => {
                                return {
                                    backgroundColor: helper.getCSSVariable(`${cssPrefix}light`)
                                };
                            }
                        }
                    }
                }
            }
        });
    })
    .catch(console.warn);
}


// Profile completion chart
const profileCompletionChart = document.getElementById('profileCompletionChart');

if(profileCompletionChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(profileCompletionChart, {
            // The type of chart we want to create
            type: 'roundedDoughnut',
        
            // The data for our dataset
            data: {
                labels: [
                    'Completed',
                    'Not completed'
                ],
                datasets: [
                    {
                        data: [75, 25],
                        backgroundColor: [
                            helper.getCSSVariable(`${cssPrefix}primary`),
                            helper.getCSSVariable(`${cssPrefix}light`)
                        ]
                    }
                ]
            },

            // Configuration options
            options: {
                plugins: {
                    tooltip: {  
                        callbacks: {
                            label: (context) => {
                                return context.parsed + '%';
                            }
                        }
                    }
                }
            }
        });
    })
    .catch(console.warn);
}


// World map
const worldMap = document.getElementById('worldMap');

if(worldMap) {
    import(/* webpackChunkName: 'jsvectormap' */ './vendors/jsvectormap')
    .then(({mapDefaultOptions}) => {
        // The data for our dataset
        const worldMapMarkers = [
            {
                coords: [-33.8481643, 150.7915504],
                name: 'Sydney',
                description: 'Hello this is Sydney'
            },
            {
                coords: [40.7127837, -74.0059413],
                name: 'New York',
                description: 'Welcom from Sydney'
            },
            {
                coords: [34.052235, -118.243683],
                name: 'Los Angeles',
                description: 'Hurray LA!'
            },
            {
                coords: [51.507351, -0.127758],
                name: 'London'
            },
            {
                coords: [19.0822375, 72.8109751],
                name: 'Mumbai'
            }
        ];
        
        // Configuration options
        const mapUserOptions = {
            selector: worldMap,
            markers: worldMapMarkers,
            onMarkerTooltipShow(event, tooltip, index) {
                tooltip.getElement().innerHTML = `${tooltip.text()}<br/>${
                    worldMapMarkers[index].description || ''
                }`;
            }
        }
        
        // Merge map options
        const options = {
            ...mapDefaultOptions,
            ...mapUserOptions
        };
    
        const map = new jsVectorMap(options);
    })
    .catch(console.warn);
}


// Total sales chart
const totalSalesChart = document.getElementById('totalSalesChart');
       
if(totalSalesChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(totalSalesChart, {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            const {ctx, chartArea} = chart;
                    
                            if (!chartArea) {
                                return null;
                            }
                            return helper.getGradient(
                                ctx,
                                chartArea,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}success-rgb`)}, 0.3)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}success-rgb`)}, 0.1)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}success-rgb`)}, 0)`
                            );
                        },
                        borderWidth: 3,
                        borderColor: helper.getCSSVariable(`${cssPrefix}success`),
                        data: [400, 1000, 1000, 2500, 3000, 1500, 4000, 5000, 8000, 6000, 5500, 6500],
                        tension: 0
                    }
                ]
            },

            // Configuration options
            options: {
                layout: {
                    padding: {
                        top: 2,
                        bottom: -10
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            display: false
                        },
                        grid: {
                            drawOnChartArea: true
                        }
                    },
                    y: {
                        display: false
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            },
                            labelColor: context => {
                                return {
                                    backgroundColor: context.dataset.borderColor
                                }
                            }
                        }
                    }
                }
            }
        });
    })
    .catch(console.warn);
}


// Total expense chart
const totalExpenseChart = document.getElementById('totalExpenseChart');
       
if(totalExpenseChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(totalExpenseChart, {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            const {ctx, chartArea} = chart;
                    
                            if (!chartArea) {
                                return null;
                            }
                            return helper.getGradient(
                                ctx,
                                chartArea,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}danger-rgb`)}, 0.3)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}danger-rgb`)}, 0.1)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}danger-rgb`)}, 0)`
                            );
                        },
                        borderWidth: 3,
                        borderColor: helper.getCSSVariable(`${cssPrefix}danger`),
                        data: [9932, 7384, 6673, 4500, 3834, 1222, 2127, 3384, 2992, 5383, 1923, 2537],
                        tension: 0
                    }
                ]
            },

            // Configuration options
            options: {
                layout: {
                    padding: {
                        top: 2,
                        bottom: -10
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            display: false
                        },
                        grid: {
                            drawOnChartArea: true
                        }
                    },
                    y: {
                        display: false
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            },
                            labelColor: context => {
                                return {
                                    backgroundColor: context.dataset.borderColor
                                }
                            }
                        }
                    }
                }
            }
        });
    })
    .catch(console.warn);
}


// Overview chart
const overviewChart = document.getElementById('overviewChart');

if(overviewChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(overviewChart, {
            // The type of chart we want to create
            type: 'line',
        
            // The data for our dataset
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Expected',
                        data: [9440, 18021, 34081, 43804, 30409, 22532, 21986, 27227, 32636, 30171, 32753, 19589],
                        borderColor: helper.getCSSVariable(`${cssPrefix}primary`),
                        
                        pointRadius: 4,
                        pointBorderWidth: 3,
                        pointBorderColor: helper.getCSSVariable(`${cssPrefix}primary`),
                        pointBackgroundColor: helper.getCSSVariable(`${cssPrefix}white`),
                        
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 3,
                        pointHoverBorderColor: helper.getCSSVariable(`${cssPrefix}primary`),
                        pointHoverBackgroundColor: helper.getCSSVariable(`${cssPrefix}primary`),
                        tension: 0
                    },
                    {
                        label: 'Actual',
                        data: [2440, 9247, 23081, 30839, 39923, 36532, 28386, 34227, 38636, 37171, 25753, 25589],
                        borderWidth: 2,
                        borderDash: [5, 5],
                        borderColor: `rgba(${helper.getCSSVariable(`${cssPrefix}blue-rgb`)}, 0.75)`,
                        pointBackgroundColor: helper.getCSSVariable(`${cssPrefix}blue`),
                        tension: 0
                    }
                ]
            },
        
            // Configuration options
            options: {
                scales: {
                    x: {
                        gridLines: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            callback: (value, index, values) => {
                                return value > 0 ? '$' + ((value < 1000000) ? Math.floor(value / 1000) + 'k' : Math.floor(value / 1000000) + 'M') : value;
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        mode: 'index',
                        callbacks: {
                            label: context => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            },
                            labelColor: context => {
                                return {
                                    backgroundColor: context.dataset.borderColor
                                }
                            }
                        }
                    },
                    mouseLine: {
                        enabled: true
                    }
                }
                
            }
        });
    })
    .catch(console.warn);
}

// Email activity chart
const emailActivityChart = document.getElementById('emailActivityChart');
        
if(emailActivityChart){
    Promise.all([
        import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    ])
    .then(() => {
        new Chart(emailActivityChart, {
            // The type of chart we want to create
            type: 'pie',
        
            // The data for our dataset
            data: {
                labels: [
                    'Opened ',
                    'Clicked',
                    'Bounce',
                    'Spam',
                ],
                datasets: [
                    {
                        data: [49, 22, 13, 5],
                        backgroundColor: [
                            `rgba(${helper.getCSSVariable(`${cssPrefix}info-rgb`)}, 0.9)`,
                            `rgba(${helper.getCSSVariable(`${cssPrefix}success-rgb`)}, 0.9)`,
                            `rgba(${helper.getCSSVariable(`${cssPrefix}warning-rgb`)}, 0.9)`,
                            `rgba(${helper.getCSSVariable(`${cssPrefix}danger-rgb`)}, 0.9)`
                        ]
                    }
                ]
            },

            // Configuration options
            options: {
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'center'
                    }
                }
            }
        });
    })
    .catch(console.warn);
}

// Revenue chart
const revenueChart = document.getElementById('revenueChart');

if(revenueChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(revenueChart, {
            // The type of chart we want to create
            type: 'line',
        
            // The data for our dataset
            data: {
                labels: ['Jan 2020', 'Feb 2020', 'Mar 2020', 'Apr 2020', 'May 2020', 'Jun 2020', 'Jul 2020', 'Aug 2020', 'Sep 2020', 'Oct 2020', 'Nov 2020', 'Dec 2020', 'Jan 2021', 'Feb 2021', 'Mar 2021', 'Apr 2021', 'May 2021', 'Jun 2021', 'Jul 2021', 'Aug 2021', 'Sep 2021', 'Oct 2021', 'Nov 2021', 'Dec 2021', 'Jan 2022', 'Feb 2022', 'Mar 2022', 'Apr 2022', 'May 2022', 'Jun 2022', 'Jul 2022', 'Aug 2022', 'Sep 2022', 'Oct 2022', 'Nov 2022', 'Dec 2022'],
                datasets: [
                    {
                        label: 'Revenue',
                        data: [1534797,1807907,2991201,3206722,3400109,4490270,4811541,5079186,5416371,5445881,5285721,5625865,6689399,7659140,8811657,8960763,9335633,9518522,10975742,12644259,13035177,13337221,14407691,15102984,15140604,15498567,15582300,16034956,15600252,17132937,17277658,17985396,19104049,19606166,19641876,19987294],
                        borderWidth: 2,
                        borderColor: helper.getCSSVariable(`${cssPrefix}blue`),
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            const {ctx, chartArea} = chart;
                    
                            if (!chartArea) {
                                return null;
                            }
                            return helper.getGradient(
                                ctx,
                                chartArea,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}blue-rgb`)}, 0.3)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}blue-rgb`)}, 0.15)`,
                                `rgba(${helper.getCSSVariable(`${cssPrefix}blue-rgb`)}, 0.05)`
                            );
                        },
                        pointBackgroundColor: helper.getCSSVariable(`${cssPrefix}blue`),
                        tension: 0
                    }
                ]
            },
        
            // Configuration options
            options: {
                scales: {
                    x: {
                        display: false
                    },
                    y: {
                        ticks: {
                            maxTicksLimit: 10,
                            callback: (value, index, values) => {
                                return value > 0 ? '$' + ((value < 1000000) ? Math.floor(value / 1000) + 'k' : Math.floor(value / 1000000) + 'M') : value;
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: context => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            },
                            labelColor: context => {
                                if(context.datasetIndex === 0) {
                                    return {
                                        backgroundColor: context.dataset.borderColor
                                    }
                                } else {
                                    return {
                                        backgroundColor: context.dataset.backgroundColor
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        });
    })
    .catch(console.warn);
}

// Volume chart
const volumeChart = document.getElementById('volumeChart');

if(volumeChart) {
    import(/* webpackChunkName: 'chart.js' */ './vendors/chart')
    .then(() => {
        new Chart(volumeChart, {
            // The type of chart we want to create
            type: 'bar',
        
            // The data for our dataset
            data: {
                labels: ['Jan 2020', 'Feb 2020', 'Mar 2020', 'Apr 2020', 'May 2020', 'Jun 2020', 'Jul 2020', 'Aug 2020', 'Sep 2020', 'Oct 2020', 'Nov 2020', 'Dec 2020', 'Jan 2021', 'Feb 2021', 'Mar 2021', 'Apr 2021', 'May 2021', 'Jun 2021', 'Jul 2021', 'Aug 2021', 'Sep 2021', 'Oct 2021', 'Nov 2021', 'Dec 2021', 'Jan 2022', 'Feb 2022', 'Mar 2022', 'Apr 2022', 'May 2022', 'Jun 2022', 'Jul 2022', 'Aug 2022', 'Sep 2022', 'Oct 2022', 'Nov 2022', 'Dec 2022'],
                datasets: [
                    {
                        label: 'Volume',
                        data: [62818,41778,48100,106833,189364,152544,203980,218007,269406,290798,357599,323096,307246,375954,408444,368624,529254,594473,514196,456412,428602,535077,628141,602323,564725,648952,690027,796537,876486,961475,816575,861616,912809,869996,904038,981902],
                        backgroundColor: helper.getCSSVariable(`${cssPrefix}primary`),
                        barThickness: 10
                    }
                ]
            },
        
            // Configuration options
            options: {
                scales: {
                    y: {
                        ticks: {
                            maxTicksLimit: 10,
                            callback: (value, index, values) => {
                                return value > 0 ? '$' + ((value < 1000000) ? Math.floor(value / 1000) + 'k' : Math.floor(value / 1000000) + 'M') : value;
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: context => {
                                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
                            },
                            labelColor: context => {
                                return {
                                    backgroundColor: context.dataset.backgroundColor
                                };
                            }
                        }
                    }
                }
                
            }
        });
    })
    .catch(console.warn);
}