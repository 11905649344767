// Wizard

'use strict';

import * as helper from './helpers';

const wizards = document.querySelectorAll('[data-toggle="wizard"]');

if(wizards.length) {
    wizards.forEach((wizard) => {
        // Toggle new tab
        let tab = new Tab(wizard);
    
        wizard.addEventListener('click', (e) => {
            e.preventDefault();

            document.querySelector(`[data-bs-target="${tab._element.hash}"]`).click();
        });

        document.querySelector(`[data-bs-target="${tab._element.hash}"]`).addEventListener('shown.bs.tab', (e) => {
            const previousSiblings = helper.getPreviousSiblings(e.target.parentNode);
            const nextSiblings = helper.getNextSiblings(e.target.parentNode);

            nextSiblings.forEach((nextSibling) => {
                nextSibling.classList.remove('visited');
            });
            previousSiblings.forEach((previousSibling) => {
                previousSibling.classList.add('visited');
            });
            e.target.parentNode.classList.remove('visited');
        })
    });
}