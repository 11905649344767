// Show/hide password

'use strict';

const passwordTogglers = document.querySelectorAll('[data-toggle-password]');

if (passwordTogglers.length) {
    passwordTogglers.forEach((passwordToggler) => {
        const passwordField = passwordToggler.closest('.input-group').querySelector('[data-toggle-password-input]');

        let showHidePassword = () => {
            if (passwordField.type == 'password') {
                passwordField.setAttribute('type', 'text');
                passwordToggler.classList.add('pw-hidden');
            } else {
                passwordToggler.classList.remove('pw-hidden');
                passwordField.setAttribute('type', 'password');
            }
        };
            
        passwordToggler.addEventListener('click', showHidePassword); 
    });
}