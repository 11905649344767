// flatpickr

'use strict';

const datepickers = document.querySelectorAll('[data-flatpickr]'); 

if(datepickers.length) {
    import(/* webpackChunkName: 'flatpickr' */ 'flatpickr')
    .then(({default: flatpickr}) => {
        datepickers.forEach((datepicker) => {
            const userOptions = datepicker.dataset.flatpickr ? JSON.parse(datepicker.dataset.flatpickr) : {};
    
            const defaultOptions = {
                dateFormat: 'm/d/Y',
                defaultHour: new Date().getHours(),
                defaultMinute: new Date().getMinutes(),
                prevArrow: '<svg height="9" height="9" class="fill-transparent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill-rule:evenodd;}</style></defs><title>arrow-left-1</title><path class="cls-1" d="M16.25,23.25,5.53,12.53a.749.749,0,0,1,0-1.06L16.25.75"/></svg>',
                nextArrow: '<svg height="9" height="9" class="fill-transparent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill-rule:evenodd;}</style></defs><title>arrow-right-1</title><path class="cls-1" d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"/></svg>'
            }
    
            const options = {
                ...defaultOptions,
                ...userOptions
            };
    
            flatpickr(datepicker, options);
        });

        window.Flatpickr = flatpickr;
    })
    .catch(console.warn);
};