/*

Theme: Dashly
Product Page: https://themes.getbootstrap.com/product/dashly-admin-dashboard-template/
Author: Webinning
Author URI: https://webinning.co.uk
Support: support@dashly-theme.com

---

Copyright 2022 Webinning

*/

'use strict';

const customizationWindow = document.getElementById('offcanvasCustomize');

if(customizationWindow && typeof themeConfig != 'undefined') {
    // Store theme config values in localstorage
    Object.keys(themeConfig).forEach((key) => {
        if (localStorage.getItem(key) === null) {
            localStorage[key] = themeConfig[key];
        }
    });

    const themeControls = document.querySelectorAll('[data-theme-control]'),
          resetButton = document.getElementById('resetThemeConfig'),
          previewButton = document.getElementById('previewThemeConfig');

    themeControls.forEach((themeControl) => {
        let controlValue = themeControl.dataset.themeControl;

        if(themeControl.type == 'radio') {
            themeControl.checked = true && themeControl.value == localStorage.getItem(controlValue);
        } else {
            themeControl.checked = localStorage.getItem(controlValue) === 'true';
        }

        themeControl.addEventListener('change', (e) => {
            localStorage[controlValue] = themeControl.type == 'radio' ? e.target.value : e.target.checked;
        });
    });

    resetButton.addEventListener('click', (e) => {
        Object.keys(themeConfig).forEach((key) => {
            localStorage[key] = themeConfig[key];
            reloadPage();
        });
    });

    previewButton.addEventListener('click', (e) => {
        reloadPage();
    });
}

// Reload page
function reloadPage() {
    window.location = window.location.pathname;
}