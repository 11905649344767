// Tooltip

'use strict';

const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');

if(tooltips.length) {
    tooltips.forEach((tooltip) => {
        new Tooltip(tooltip);
    });
}