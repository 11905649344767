// gumshoejs (scrollspy)

'use strict';

const scrollspy = document.querySelector('[data-scrollspy]'); 

if(scrollspy) {
    import(/* webpackChunkName: 'gumshoe' */ 'gumshoejs')
    .then(({default: Gumshoe}) => {
        const userOptions = scrollspy.dataset.scrollspy ? JSON.parse(scrollspy.dataset.scrollspy) : {};

        const defaultOptions = {
            reflow: true
        }

        const options = {
            ...defaultOptions,
            ...userOptions
        };

        new Gumshoe('[data-scrollspy] a', options);

        window.Gumshoe = Gumshoe;
    })
    .catch(console.warn);
};