// Helpers

'use strict';

import getGradient from './getGradient';
import getCSSVariable from './getCSSVariable';
import hexToRgbA from './hexToRgba';
import setID from './setId';
import getPreviousSiblings from './getPreviousSiblingNodes';
import getNextSiblings from './getNextSiblingNodes';
import getSubstringBetween from './getSubstringBetween';
import shadeColor from './shadeColor';

export {
    getGradient,
    getCSSVariable,
    hexToRgbA,
    setID,
    getPreviousSiblings,
    getNextSiblings,
    getSubstringBetween,
    shadeColor
};