// Get gradient

'use strict';

export default function getGradient(ctx, chartArea, ...gradients) {
    let width, height, gradient;
    
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;

    if (gradient === null || width !== chartWidth || height !== chartHeight) {
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, 0, 0, height);

        for (let i = 0; i < gradients.length; i++) {
            gradient.addColorStop(i * 0.5, gradients[i]);
        }
    }
    return gradient;
}
