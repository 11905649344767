// Table

'use strict';

// Select/deselect all checkboxes
let parentCheckbox = document.getElementById('checkAllCheckboxes');

if(parentCheckbox) {
    parentCheckbox.addEventListener('change', (e) => {
        document.querySelectorAll('.form-check-input').forEach((checkbox) => {
            checkbox.checked = e.target.checked;
        });
    });
        
    document.querySelectorAll('tbody .form-check-input').forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
            const tbodyCheckbox = document.querySelectorAll('tbody .form-check-input').length,
                  tbodyCheckedbox = document.querySelectorAll('tbody .form-check-input:checked').length;
    
            if(tbodyCheckbox == tbodyCheckedbox){
                parentCheckbox.indeterminate = false;
                parentCheckbox.checked = true;
            }
            if (tbodyCheckbox > tbodyCheckedbox && tbodyCheckedbox >= 1) {
                parentCheckbox.indeterminate = true;
            }
            if(tbodyCheckedbox == 0) {
                parentCheckbox.indeterminate = false;
                parentCheckbox.checked = false;
            }
    
        });
    });
}