// Tab link

'use strict';

const tabLinks = document.querySelectorAll('[data-toggle="tabLink"]');

if(tabLinks.length) {
    tabLinks.forEach((tabLink) => {
        tabLink.addEventListener('click', (e) => {
            e.preventDefault();
    
            document.querySelector(`[data-bs-target="${e.target.hash}"]`).click();
        });
    });
}