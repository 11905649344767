// PicMo (emoji picker)

'use strict';

const emojiPickers = document.querySelectorAll('[data-emoji-picker]');
const emojiPopups = document.querySelectorAll('[data-emoji-popup]');

if(emojiPickers.length) {
    import(/* webpackChunkName: 'picmo' */ 'picmo')
    .then(({createPicker}) => {
        emojiPickers.forEach((emojiPicker) => {
            const picker = new createPicker({
                rootElement: emojiPicker,
                emojiSize: '1.75rem'
            });

            let input = document.querySelector(emojiPicker.dataset.emojiTarget);

            if (input) {
                picker.addEventListener('emoji:select', (selection) => {
                    typeInTextarea(selection.emoji, input);
                    input.focus();
                });
            }
        });
    })
    .catch(console.warn);
}

if(emojiPopups.length) {
    Promise.all([
        import(/* webpackChunkName: 'picmo.popup-picker' */ '@picmo/popup-picker'),
        import(/* webpackChunkName: 'picmo' */ 'picmo')
    ])
    .then(([{createPopup}]) => {
        emojiPopups.forEach((emojiPopup) => {
            const popup = new createPopup({
                emojiSize: '1.75rem'
            },{
                referenceElement: emojiPopup,
                triggerElement: emojiPopup,
                showCloseButton: false,
                position: 'top-start',
                className: 'emoji-popup'
            });
    
            let input = document.querySelector(emojiPopup.dataset.emojiTarget);
    
            if (input) {
                popup.addEventListener('emoji:select', (selection) => {
                    typeInTextarea(selection.emoji, input);
                    input.focus();
                });
                
                emojiPopup.addEventListener('click', () => {
                    popup.toggle(emojiPopup);
                }); 
            }
        });
    })
    .catch(console.warn);
}

function typeInTextarea(newText, el = document.activeElement) {
    const [start, end] = [el.selectionStart, el.selectionEnd];
    el.setRangeText(newText, start, end, 'end');
}